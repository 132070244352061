export const authUserMutator = async (state, authUser) => {
  state.authUserState = authUser
}
export const hasAnsweredCallMutator = async (state, payload) => {
  state.hasAnsweredCall = payload
}

export const sipMutator = async (state, payload) => {
  state.sipState[payload.type] = payload.data
}

export const callMutator = async (state, payload) => {
  state.callState[payload.type] = payload.data
}

export const serialMutator = async (state, payload) => {
  state.serialDevices = payload
}

export const updateGlobalRingtonesMutator = async (state, payload) => {
  state.globalRingtones = payload
}

export const updateRealmRingtonesMutator = async (state, payload) => {
  state.realmRingtones = payload
}

export const changeFontSizeMutator = async (state, payload) => {
  state.fontsize = payload
}

export const clearingMapMutator = async (state, payload) => {
  state.callState.clearingMap = payload
}
