import localStorageService from '@/util/local-storage-service'

export default () => ({

  settingsProxyState: [],
  addresses: [],
  sipDefaultAddress: null,
  egressAuthState: null,
  accounts: [],
  accountNames: [],

  audioSettings: {
    microphonePermissionState: null,
    audioInputs: [],
    audioOutputs: [],
    ringingOutputDeviceId: localStorageService.getRingingOutputId() || 'default',
    microphoneDeviceId: localStorageService.getMicrophoneId() || 'default',
    callerOutputDeviceId: localStorageService.getCallerOutputId() || 'default',
    itrOutputDeviceId: localStorageService.getItrOutputId() || 'default',
    recordingsOutputDeviceId: localStorageService.getRecordingsOutputId() || 'default',
    ringingVolume: localStorageService.getRingingVolume() || 0.8,
    callerVolume: localStorageService.getCallerVolume() || 0.8,
    itrVolume: localStorageService.getItrVolume() || 0.8,
    recordingsVolume: localStorageService.getRecordingsVolume() || 0.8
  },

  userSettings: [],
  globalSettings: []
})
