import { liveQuery } from 'dexie'
import { useObservable } from '@vueuse/rxjs'

export default function contactBookDBModule (db) {
  db.version(15).stores({
    contactBooks: '++id, *contact_book_group_ids'
  })

  return {
    insert: (data) => {
      data.id = parseInt(data.id)
      data.contact_book_group_ids = data.contact_book_group_ids.map(i => parseInt(i))

      db.contactBooks.add(data)
    },
    insertMany: (data) => {
      data.forEach(d => {
        d.id = parseInt(d.id)
        d.contact_book_group_ids = d.contact_book_group_ids.map(i => parseInt(i))

        db.contactBooks.add(d)
      })
    },
    get: (filter) => {
      return useObservable(
        liveQuery(async () => {
          let tmp = db.contactBooks

          filter = filter || {}

          if (filter.group_id) {
            tmp = tmp.where('contact_book_group_ids').equals(filter.group_id)
          }

          if (filter.search) {
            filter.search = filter.search.replaceAll('+', '\\+')

            tmp = tmp.filter((value) => {
              return value.phone.phone.match(new RegExp(filter.search, 'ig')) || value.phone.name.match(new RegExp(filter.search, 'ig'))
            })
          }

          tmp = tmp.reverse()

          if (filter.offset) {
            tmp = tmp.offset(filter.offset)
          }

          if (filter.limit) {
            tmp = tmp.limit(filter.limit)
          }

          return await tmp.toArray()
        })
      )
    },
    delete: (id) => {
      db.contactBooks.delete(id)
    },
    edit: async (id, data) => {
      db.contactBooks.update(id, data)
    },
    clear: () => {
      db.contactBooks.clear()
    },
    last: () => {
      return db.contactBooks.orderBy('id').last()
    },
    count: (filter) => {
      return useObservable(
        liveQuery(async () => {
          if (!filter) return db.contactBooks.count()

          let tmp = db.contactBooks

          if (filter.group_id) {
            tmp = tmp.where('contact_book_group_ids').equals(filter.group_id)
          }

          if (filter.search) {
            filter.search = filter.search.replaceAll('+', '\\+')

            tmp = tmp.filter((value) => {
              return value.phone.phone.match(new RegExp(filter.search, 'ig')) || value.phone.name.match(new RegExp(filter.search, 'ig'))
            })
          }

          return tmp.count()
        })
      )
    },
    sync: (data) => {
      data.forEach(async d => {
        d.id = parseInt(d.id)
        d.contact_book_group_ids = d.contact_book_group_ids.map(i => parseInt(i))

        if (await db.contactBooks.get(d.id)) {
          db.contactBooks.update(d.id, d)
        } else {
          db.contactBooks.add(d)
        }
      })
    }
  }
}
