
import store from '@/store'

const mediaHelper = {

  async sipRinging (sinkId = 'default', ringtoneType = 'ringing_tones_911') {
    let customRingtone = null

    try {
      customRingtone = store.getters['global/getRingtoneByType'](ringtoneType)
    } catch (e) {
      console.log(e)
    }

    const ringingAudio = document.getElementById('ringing-audio')

    if (customRingtone) {
      ringingAudio.src = customRingtone
    } else {
      ringingAudio.src = require('@/assets/audio/sounds/ringing.ogg')
    }

    /* ringingAudio.loop = true

    try {
      ringingAudio.volume = parseFloat(localStorage.getItem('ringingVolume'))
      await ringingAudio.setSinkId(sinkId.deviceId)
    } catch (e) {
      ringingAudio.volume = 1
      await ringingAudio.setSinkId('default')
    }

    await ringingAudio.play()

    return ringingAudio */
  },

  setBusyCallRingtone () {
    const ringingAudio = document.getElementById('ringing-busy-audio')

    ringingAudio.src = require('@/assets/audio/sounds/busy-signal.mp3')

    ringingAudio.loop = false
    ringingAudio.volume = 1
    ringingAudio.play()
  },

  setNewAbandonCallAudio () {
    let ringtone = null

    try {
      ringtone = store.getters['global/getRingtoneByType']('new_abandon_call')
    } catch (e) {
      console.log(e)
    }

    if (!ringtone) return

    const audio = document.getElementById('new-abandon-call')

    if (!audio) return

    audio.src = ringtone
    audio.loop = false
    audio.volume = 1
  },

  async checkIfAudioInputDeviceExists (deviceId) {
    return await navigator.mediaDevices.enumerateDevices()
      .then(data => {
        return !!data.find(item => item.deviceId === deviceId)
      })
  }

}

export default mediaHelper
