<template>
  <v-container no-gutters>
    <v-row>
      <v-col cols="12">

        <!-- eslint-disable -->
        <v-tabs class="font-weight-bold" height="40" color="white" background-color="transparent" v-model="tab"
          show-arrows>
          <template v-for="(item, index) in tabs">
            <v-tab :key="index" :cypress="item.cypress" @change="changeTab(item.name)" v-if="$can(item.permission)">
              {{ item.name }}
            </v-tab>
          </template>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabs" :key="item.name" :cypress=true :eager="item.eager"
            v-if="$can(item.permission)">
            <v-card tile flat color="secondary_card">
              <v-card-text>
                <component :is="item.component"></component>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <!-- eslint-enable -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* Tabs */
import SipSettingsTab from '@/app/widgets/settings-widget/components/tabs/SipSettingTab'
import LayoutSettingTab from '@/app/widgets/settings-widget/components/tabs/LayoutSettingTab'
import GeneralSettingTab from '@/app/widgets/settings-widget/components/tabs/GeneralSettingTab'
import AudioArbitrationTab from '@/app/widgets/settings-widget/components/tabs/AudioArbitrationTab'
import ConnectionSettingTab from '@/app/widgets/settings-widget/components/tabs/ConnectionSettingTab'
import ControllerSettingTab from '@/app/widgets/settings-widget/components/tabs/ControllerSettingTab'
import CADSettingTab from '@/app/widgets/settings-widget/components/tabs/CADSettingTab'

export default {
  name: 'SettingsComponent',
  data: (vm) => ({
    tab: null,
    name: vm.$t('sip_address')
  }),
  computed: {
    tabs () {
      return [
        { name: this.$t('sip_address'), component: SipSettingsTab, eager: true, permission: 'can_see_sip_address' },
        { name: this.$t('connection_settings'), component: ConnectionSettingTab, eager: true, permission: 'can_see_connection_settings', cypress: 'connection_settings' },
        { name: this.$t('audio_settings'), component: AudioArbitrationTab, eager: true, permission: 'can_see_audio_settings' },
        { name: this.$t('controller_settings'), component: ControllerSettingTab, permission: 'can_see_controller_settings' },
        { name: this.$t('layout'), component: LayoutSettingTab, permission: 'can_see_layout_settings' },
        { name: this.$t('cad_settings'), component: CADSettingTab, permission: 'can_see_cad_settings' },
        { name: this.$t('general_settings'), component: GeneralSettingTab, permission: 'can_see_general_settings' }
      ]
    }
  },
  methods: {
    changeTab (item) {
      this.name = item
    }
  }
}
</script>
