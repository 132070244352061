export const ringingMutator = async (state, payload) => {
  state.ringingState = payload
}

export const testRingingMutator = async (state, payload) => {
  state.testRingingState = payload
}

export const testCallerMutator = async (state, payload) => {
  state.testCallerState = payload
}

export const testItrMutator = async (state, payload) => {
  state.testItrState = payload
}

export const testRecordingsMutator = async (state, payload) => {
  state.testRecordingsState = payload
}
