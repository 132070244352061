<template>
  <v-dialog eager @input="change" value="true" :max-width="width" :persistent="persistent" @keydown.esc="choose(false)">
    <v-card tile color="secondary_card" :dark="$vuetify.theme.dark" >

      <v-toolbar color="primary_card" v-if="Boolean(title)" flat  >
        <v-icon v-if="Boolean(icon)" left :color="color">{{ icon }}</v-icon>
        <v-toolbar-title color="primary" :dark="$vuetify.theme.dark"  v-text="title"/>

        <v-spacer />

        <v-icon @click="choose(false)" :dark="$vuetify.theme.dark" class="pointer">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text  class="text-h6 py-3" v-html="message"/>

      <v-card-text class="text-right">
        <v-btn color="grey" class="mr-5"
               cypress="confirm_false"
               v-if="Boolean(buttonFalseText)"

               :text="buttonFalseFlat"
               @click="choose(false)"
        >
          {{ buttonFalseText }}
        </v-btn>
        <v-btn color="red"
               cypress="confirm_true"
               v-if="Boolean(buttonTrueText)"

               :text="buttonTrueFlat"
               @click="choose(true)"
        >
          {{ buttonTrueText }}
        </v-btn>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import { VCard, VCardText, VDialog, VIcon, VToolbar, VToolbarTitle, VSpacer, VBtn } from 'vuetify/lib'

export default {
  components: {
    VCard,

    VCardText,
    VDialog,
    VIcon,
    VToolbar,
    VToolbarTitle,
    VSpacer,
    VBtn
  },
  props: {
    buttonTrueText: {
      type: String,
      default: 'Yes'
    },
    buttonFalseText: {
      type: String,
      default: 'No'
    },
    buttonTrueColor: {
      type: String,
      default: 'primary'
    },
    buttonFalseColor: {
      type: String,
      default: 'grey'
    },
    buttonFalseFlat: {
      type: Boolean,
      default: true
    },
    buttonTrueFlat: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'warning'
    },
    icon: {
      type: String,
      default () {
        return this.$vuetify.icons.values.warning
      }
    },
    message: {
      type: String,
      required: true
    },
    persistent: Boolean,
    title: {
      type: String
    },
    width: {
      type: Number,
      default: 450
    }
  },
  data () {
    return {
      value: false
    }
  },
  mounted () {
    this.$event.listen('keyup', this.onEnterPressed)
  },
  destroyed () {
    document.removeEventListener('keyup', this.onEnterPressed)
  },
  methods: {
    onEnterPressed (e) {
      if (e.keyCode === 13) {
        e.stopPropagation()
        this.choose(true)
      }
    },
    choose (value) {
      this.$emit('result', value)
      this.value = value
      this.$destroy()
    },
    change (res) {
      this.$destroy()
    }
  }
}
</script>
