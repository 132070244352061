import store from '@/store'

const localStorageService = (function () {
  const _service = this

  function _getService () {
    return _service
  }

  function _setUserTokens ({ userAccessToken, refreshToken }) {
    localStorage.setItem('user_access_token', userAccessToken)
    localStorage.setItem('refresh_token', refreshToken)
  }

  function _setAccountId ({ accountId }) {
    localStorage.setItem('account_id', accountId)
  }

  function _getAccountId () {
    return localStorage.getItem('account_id')
  }

  function _getUserAccessToken () {
    return localStorage.getItem('user_access_token')
  }

  function _getRefreshToken () {
    return localStorage.getItem('refresh_token')
  }

  function _clearLocalStorageOnLogout () {
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('user_access_token')
    localStorage.removeItem('userId')
    localStorage.removeItem('workspace_id')
    localStorage.removeItem('sync_timestamp')
    localStorage.removeItem('cache')
  }

  function _getMapStyle () {
    return localStorage.getItem('map_style')
  }

  function _setMapStyle (val) {
    localStorage.setItem('map_style', val)
  }

  function _getRingingOutputId () {
    return localStorage.getItem('ringing_output_id')
  }

  function _setRingingOutputId (val) {
    if (Array.isArray(val)) {
      const device = val.filter(item => item.deviceId === this.getRingingOutputId())

      if (device.length > 0) {
        store.commit('settings/audioSettingsMutation', { type: 'ringingOutputDeviceId', data: this.getRingingOutputId() })
        localStorage.setItem('ringing_output_id', this.getRingingOutputId())
      } else {
        store.commit('settings/audioSettingsMutation', { type: 'ringingOutputDeviceId', data: 'default' })
        localStorage.setItem('ringing_output_id', 'default')
      }
    } else {
      store.commit('settings/audioSettingsMutation', { type: 'ringingOutputDeviceId', data: val })
      localStorage.setItem('ringing_output_id', val)
    }
  }

  function _getMicrophoneId () {
    return localStorage.getItem('microphone_id')
  }

  function _setMicrophoneId (val) {
    if (Array.isArray(val)) {
      const device = val.filter(item => item.deviceId === this.getMicrophoneId())

      if (device.length > 0) {
        store.commit('settings/audioSettingsMutation', { type: 'microphoneDeviceId', data: this.getMicrophoneId() })
        localStorage.setItem('microphone_id', this.getMicrophoneId())
      } else {
        store.commit('settings/audioSettingsMutation', { type: 'microphoneDeviceId', data: 'default' })
        localStorage.setItem('microphone_id', 'default')
      }
    } else {
      store.commit('settings/audioSettingsMutation', { type: 'microphoneDeviceId', data: val })
      localStorage.setItem('microphone_id', val)
    }
  }
  async function _setMicrophoneSource (session) {
    if (session?._status === 9) {
      const constraints = {
        audio: { deviceId: this.getMicrophoneId() },
        video: false
      }

      await navigator.getUserMedia(constraints, stream => {
        session.connection.getSenders().forEach(sender => {
          sender.replaceTrack(stream.getTracks()[0]).then(r => {
          })
        })
      }, e => console.log(e))
    }
  }

  function _getCallerOutputId () {
    return localStorage.getItem('caller_output_id')
  }

  function _setCallerOutputId (val) {
    if (Array.isArray(val)) {
      const device = val.filter(item => item.deviceId === this.getCallerOutputId())

      if (device.length > 0) {
        store.commit('settings/audioSettingsMutation', { type: 'callerOutputDeviceId', data: this.getCallerOutputId() })
        localStorage.setItem('caller_output_id', this.getCallerOutputId())
      } else {
        store.commit('settings/audioSettingsMutation', { type: 'callerOutputDeviceId', data: 'default' })
        localStorage.setItem('caller_output_id', 'default')
      }
    } else {
      store.commit('settings/audioSettingsMutation', { type: 'callerOutputDeviceId', data: val })
      localStorage.setItem('caller_output_id', val)
    }
  }

  function _getItrOutputId () {
    return localStorage.getItem('itr_output_id')
  }

  function _getRecordingsOutputId () {
    return localStorage.getItem('recordings_output_id')
  }

  function _setItrOutputId (val) {
    if (Array.isArray(val)) {
      const device = val.filter(item => item.deviceId === this.getItrOutputId())

      if (device.length > 0) {
        store.commit('settings/audioSettingsMutation', { type: 'itrOutputDeviceId', data: this.getItrOutputId() })
        localStorage.setItem('itr_output_id', this.getItrOutputId())
      } else {
        store.commit('settings/audioSettingsMutation', { type: 'itrOutputDeviceId', data: 'default' })
        localStorage.setItem('itr_output_id', 'default')
      }
    } else {
      store.commit('settings/audioSettingsMutation', { type: 'itrOutputDeviceId', data: val })
      localStorage.setItem('itr_output_id', val)
    }
  }

  function _setRecordingsOutputId (val) {
    if (Array.isArray(val)) {
      const device = val.filter(item => item.deviceId === this.getRecordingsOutputId())

      if (device.length > 0) {
        store.commit('settings/audioSettingsMutation', { type: 'recordingsOutputDeviceId', data: this.getRecordingsOutputId() })
        localStorage.setItem('recordings_output_id', this.getRecordingsOutputId())
      } else {
        store.commit('settings/audioSettingsMutation', { type: 'recordingsOutputDeviceId', data: 'default' })
        localStorage.setItem('recordings_output_id', 'default')
      }
    } else {
      store.commit('settings/audioSettingsMutation', { type: 'recordingsOutputDeviceId', data: val })
      localStorage.setItem('recordings_output_id', val)
    }
  }

  function _getRingingVolume () {
    return parseFloat(localStorage.getItem('ringing_volume'))
  }

  function _setRingingVolume (val) {
    store.commit('settings/audioSettingsMutation', { type: 'ringingVolume', data: val })
    localStorage.setItem('ringing_volume', val.toString())
  }

  function _getCallerVolume () {
    return parseFloat(localStorage.getItem('caller_volume'))
  }

  function _setCallerVolume (val) {
    store.commit('settings/audioSettingsMutation', { type: 'callerVolume', data: val })
    localStorage.setItem('caller_volume', val.toString())
  }

  function _getItrVolume () {
    return parseFloat(localStorage.getItem('itr_volume'))
  }

  function _getRecordingsVolume () {
    return parseFloat(localStorage.getItem('recordings_volume'))
  }

  function _setItrVolume (val) {
    store.commit('settings/audioSettingsMutation', { type: 'itrVolume', data: val })
    localStorage.setItem('itr_volume', val.toString())
  }

  function _setRecordingsVolume (val) {
    store.commit('settings/audioSettingsMutation', { type: 'recordingsVolume', data: val })
    localStorage.setItem('recordings_volume', val.toString())
  }

  function _setThemeStyle (val) {
    localStorage.setItem('theme_style', val)
  }

  function _getThemeStyle () {
    const val = localStorage.getItem('theme_style')

    if (!val) return true

    return val.toLowerCase() === 'true'
  }

  function _getSyncTimestamp () {
    return parseInt(localStorage.getItem('sync_timestamp')) || 0
  }

  function _setSyncTimestamp (val) {
    localStorage.setItem('sync_timestamp', val)
  }

  function _removeSyncTimestamp (val) {
    localStorage.removeItem('sync_timestamp')
  }

  function _setPositionNumber (val) {
    localStorage.setItem('position_number', val)
  }

  function _getPositionNumber () {
    return localStorage.getItem('position_number')
  }

  function _removePositionNumber () {
    localStorage.removeItem('position_number')
  }

  function _getLanguage () {
    const lang = localStorage.getItem('language')
    if (!lang) {
      return 'en'
    }
    return lang
  }
  function _setLanguage (val) {
    localStorage.setItem('language', val)
  }
  function _removeLanguage () {
    localStorage.removeItem('language')
  }

  function _getDefaultDashboard () {
    return localStorage.getItem('default_dashboard')
  }
  function _setDefaultDashboard (val) {
    localStorage.setItem('default_dashboard', val)
  }

  function _setFontSize (val) {
    localStorage.setItem('font_size', val)
  }

  function _getFontSize () {
    return localStorage.getItem('font_size')
  }

  return {
    getService: _getService,
    getMapStyle: _getMapStyle,
    setMapStyle: _setMapStyle,
    setUserTokens: _setUserTokens,
    getUserAccessToken: _getUserAccessToken,
    getRefreshToken: _getRefreshToken,
    clearLocalStorageOnLogout: _clearLocalStorageOnLogout,
    setAccountId: _setAccountId,
    getAccountId: _getAccountId,
    getRingingOutputId: _getRingingOutputId,
    setRingingOutputId: _setRingingOutputId,
    getMicrophoneId: _getMicrophoneId,
    setMicrophoneId: _setMicrophoneId,
    setMicrophoneSource: _setMicrophoneSource,
    getCallerOutputId: _getCallerOutputId,
    setCallerOutputId: _setCallerOutputId,
    getItrOutputId: _getItrOutputId,
    setItrOutputId: _setItrOutputId,
    getRecordingsOutputId: _getRecordingsOutputId,
    setRecordingsOutputId: _setRecordingsOutputId,
    getRingingVolume: _getRingingVolume,
    setRingingVolume: _setRingingVolume,
    getCallerVolume: _getCallerVolume,
    setCallerVolume: _setCallerVolume,
    getItrVolume: _getItrVolume,
    setItrVolume: _setItrVolume,
    getRecordingsVolume: _getRecordingsVolume,
    setRecordingsVolume: _setRecordingsVolume,
    setThemeStyle: _setThemeStyle,
    getThemeStyle: _getThemeStyle,
    getSyncTimestamp: _getSyncTimestamp,
    setSyncTimestamp: _setSyncTimestamp,
    removeSyncTimestamp: _removeSyncTimestamp,
    setPositionNumber: _setPositionNumber,
    getPositionNumber: _getPositionNumber,
    removePositionNumber: _removePositionNumber,
    getLanguage: _getLanguage,
    setLanguage: _setLanguage,
    removeLanguage: _removeLanguage,
    getDefaultDashboard: _getDefaultDashboard,
    setDefaultDashboard: _setDefaultDashboard,
    setFontSize: _setFontSize,
    getFontSize: _getFontSize
  }
})()

export default localStorageService
