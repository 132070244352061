import Vue from 'vue'
import VueRouter from 'vue-router'

/* Middleware */
import auth from '@/middleware/auth'
import guest from '@/middleware/guest'

/* Pages */
const LoginPage = () => import('@/app/pages/LoginPage')
const DashboardPage = () => import('@/app/pages/DashboardPage')
const ConferencePage = () => import('@/app/pages/ConferencePage')

const RealmDashboardPage = () => import('@/app/widgets/dashboard-widget/pages/RealmDashboardPage')
const GlobalDashboardPage = () => import('@/app/widgets/dashboard-widget/pages/GlobalDashboardPage')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardPage,
    beforeEnter: async (to, from, next) => {
      await auth({ next, router })
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    beforeEnter: (to, from, next) => {
      guest({ next, router })
    }
  },
  {
    path: '/global-dashboard-configure/:id',
    name: 'global-dashboard-configure',
    component: GlobalDashboardPage,
    beforeEnter: async (to, from, next) => {
      await auth({ next, router })
    }
  },
  {
    path: '/realm-dashboard-configure/:id',
    name: 'realm-dashboard-configure',
    component: RealmDashboardPage,
    beforeEnter: async (to, from, next) => {
      await auth({ next, router })
    }
  },
  {
    path: '/conference/:type/:id',
    name: 'Conference',
    component: ConferencePage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
