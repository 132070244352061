export default () => ({
  layouts: [],
  layout: {
    template: 'DashboardTemplate1Component',

    default_templates: [
      {
        label: 'Template 1',
        value: 'DashboardTemplate1Component'
      },
      {
        label: 'Template 2',
        value: 'DashboardTemplate2Component'
      },
      {
        label: 'Template 3',
        value: 'DashboardTemplate3Component'
      },
      {
        label: 'Template 4',
        value: 'DashboardTemplate4Component'
      },
      {
        label: 'Template 5',
        value: 'DashboardTemplate5Component'
      }
    ],
    default_widget_settings: {
      CallControlComponent: {
        configurable: true,
        buttons: {
          ready_state_button: {
            visible: true,
            order: 1,
            cols: 12
          },
          local_hold_button: {
            visible: true,
            order: 2,
            cols: 6
          },
          global_hold_button: {
            visible: true,
            order: 3,
            cols: 6
          },
          mute_button: {
            visible: true,
            order: 4,
            cols: 6
          },
          playback_button: {
            visible: true,
            order: 5,
            cols: 6
          },
          hang_up_button: {
            visible: true,
            order: 6,
            cols: 12
          },
          conference_button: {
            visible: true,
            order: 7,
            cols: 6
          },
          flash_hook_button: {
            visible: true,
            order: 8,
            cols: 6
          },
          tty_rtt_text_button: {
            visible: true,
            order: 9,
            cols: 6
          },
          irr_instant_button: {
            visible: true,
            order: 10,
            cols: 6
          },
          irr_playback_button: {
            visible: true,
            order: 11,
            cols: 6
          },
          irr_voice_button: {
            visible: true,
            order: 12,
            cols: 6
          }
        }
      },
      EmergencyCallTabsComponent: {
        configurable: true,
        tabs: {
          call_information: {
            visible: true,
            order: 1
          },
          held: {
            visible: true,
            order: 2
          },
          adr: {
            visible: true,
            order: 3
          }
        }
      },
      CallHistoryTabsComponent: {
        configurable: true,
        tabs: {
          call_history: {
            visible: true,
            order: 1
          },
          abandoned_call: {
            visible: true,
            order: 2
          },
          fcc_master_registry: {
            visible: true,
            order: 3
          }
        }
      },
      SpeedDialTabsComponent: {
        configurable: true,
        fill_height: false,
        tabs: {
          speed_dial: {
            visible: true,
            order: 1
          },
          contact_book: {
            visible: true,
            order: 2
          },
          agents: {
            visible: true,
            order: 3
          }
        }
      },
      LogoAndClockComponent: {
        configurable: false
      },
      EmergencyCallLineComponent: {
        configurable: false
      },
      AdminCallComponent: {
        configurable: false
      },
      MapsTab: {
        configurable: false
      },

      LogoComponent: {
        configurable: false
      },
      ClockComponent: {
        configurable: false
      },

      ReadyStateButton: {
        configurable: false
      },
      LocalHoldButton: {
        configurable: false
      },
      GlobalHoldButton: {
        configurable: false
      },
      MuteButton: {
        configurable: false
      },
      PlaybackButton: {
        configurable: false
      },
      HangUpButton: {
        configurable: false
      },
      ConferenceButton: {
        configurable: false
      },
      TransferButton: {
        configurable: false
      },
      TtyRttTextButton: {
        configurable: false
      },
      IrrInstantButton: {
        configurable: false
      },
      IrrPlaybackButton: {
        configurable: false
      },
      IrrVoiceButton: {
        configurable: false
      },
      DialPadComponent: {
        configurable: false
      }
    },

    empty_slots: {
      slot_1: [],
      slot_2: [],
      slot_3: [],
      slot_4: [],
      slot_5: [],
      slot_6: [],
      slot_7: [],
      slot_8: [],
      slot_9: [],
      slot_10: [],
      slot_11: []
    },
    default_slots: {
      slot_1: [
        'CallControlComponent'
      ],
      slot_2: [
        'EmergencyCallTabsComponent'
      ],
      slot_3: [
        'CallHistoryTabsComponent'
      ],
      slot_4: [
        'LogoAndClockComponent',
        'SpeedDialTabsComponent'
      ],
      slot_5: [
        'EmergencyCallLineComponent'
      ],
      slot_6: [
        'AdminCallComponent'
      ],
      slot_7: [
        'MapsTab'
      ],
      slot_8: [],
      slot_9: [],
      slot_10: [],
      slot_11: []
    },
    slots: {},
    widget_settings: {}
  }
})
