import apiService from '@/modules/api/csp'
import LocalCacher from '@/modules/cache/localCacher'
import indexedDBSyncService from '@/util/indexedDB-sync-service'

export async function getContactBookAction ({ commit }, payload = {}) {
  indexedDBSyncService.sync()
}

export async function createContactBookAction ({ context, dispatch }, payload) {
  await apiService.contactBook.create(payload)
  return await dispatch('getContactBookAction')
}

export async function updateContactBookAction ({ commit, context, dispatch }, payload) {
  await apiService.contactBook.edit(payload.id, payload.data)
  await dispatch('getContactBookAction')
}

export async function deleteContactBookAction ({ commit, context, dispatch }, payload) {
  await apiService.contactBook.delete(payload.id)
  LocalCacher.indexDB.contactBooks.delete(payload.id)
}

/**
 * get Contact book Groups
 * @param commit
 * @param payload
 * @returns {Promise<*>}
 */
export async function getContactGroupsAction ({ commit }, payload) {
  indexedDBSyncService.sync()
}
