export default () => ({
  authUserState: null,
  sipState: {
    connecting: false,
    connected: false,
    registering: false,
    registered: false,
    reconnection: false,
    reRegistering: false
  },
  callState: {
    canMute: false,
    canLocalHold: false,
    canGlobalHold: false,
    isGlobalHoldCall: false,
    localMuted: false,
    remoteMuted: false,
    localHold: false,
    remoteHold: false,
    calling: false,
    hasActiveCall: false,
    isConference: false,
    isEmergency: false,
    isAdmin: false,
    isMsrp: false,
    isRtt: false,
    transferring: false,
    clearingMap: false
  },
  serialDevices: [],

  globalRingtones: [],
  realmRingtones: [],
  fontsize: null
})
