
import apiService from '@/modules/api/csp'
import localStorageService from '@/util/local-storage-service'

export const settingsProxyAction = async ({ commit, rootGetters }) => {
  await apiService.connectionSettings.getConnectionSettings().then(response => {
    // Use default proxy from env if user dont have proxy defined in settings

    const data = response.data

    if (data.length === 0) {
      let urls = process.env.VUE_APP_CONNECTION_SETTINGS_PROXY

      if (urls) {
        urls = urls.split(',')

        let priority = urls.length * 10

        urls.forEach(url => {
          data.push({
            priority: priority,
            proxy: url,
            user_id: rootGetters['global/authUserGetter'].id
          })

          priority -= 10
        })
      }
    }
    commit('settingsProxyMutator', data)
    return data
  })
}

export const addSettingsProxyAction = async ({ commit }, payload) => {
  commit('addSettingsProxyMutator', payload)
}

export const deleteSettingsProxyAction = async ({ commit }, payload) => {
  commit('deleteSettingsProxyMutator', payload)
}

export const updateSettingsProxyAction = async ({ commit }, payload) => {
  commit('updateSettingsProxyMutator', payload)
}

export const sipAddressesAction = async ({ commit }, payload) => {
  await apiService.sip.getSipAddresses(payload)
    .then(data => {
      commit('sipAddressesMutator', data)
    }).catch(e => e.message)
}

/**
 * Get default sip addresses
 * @param commit
 * @returns {Promise<void>}
 */
export const sipDefaultAddressAction = async ({ commit }) => {
  await apiService.sip.getDefaultSipAddresses()
    .then(data => {
      commit('sipDefaultAddressMutator', data)
    }).catch(e => e.message)
}

/**
 * Get authorization from egress, set auth in vuex and accountId in local storage
 * @param commit
 * @param payload
 * @returns {Promise<void>}
 */
export const egressAuthAction = async ({ commit }, payload) => {
  await apiService.egress.getAuthorization(payload)
    .then(response => {
      commit('egressAuthMutator', response.data)

      localStorageService.setAccountId({
        accountId: response.data.data.account_id
      })
    }).catch(e => e.message)
}

export const getAccountsAction = async ({ commit, dispatch }, payload) => {
  await apiService.egress.getAccounts(localStorageService.getAccountId(), {
    page_size: 1000
  })
    .then(response => {
      commit('setAccountsMutator', response.data.data)
      commit('setAccountNamesMutator', response.data.data)
    }).catch(e => e.message)
}

/**
 * Load user specific setting from api and commit to store
 * @param commit
 * @returns {Promise<void>}
 */
export const loadUserSettingsAction = async ({ commit }) => {
  await apiService.userSettings.load().then(resposne => {
    commit('userSettingsMutator', resposne.data)
    return resposne.data
  })
}

/**
 * Set user settings action
 * @param commit
 * @param payload
 * @returns {Promise<void>}
 */
export const setUserSettingsAction = async ({ commit }, payload) => {
  commit('userSettingsMutator', payload)
}

/**
 * Load global settings from api and commit to store
 * @param commit
 * @returns {Promise<void>}
 */
export const loadGlobalSettingsAction = async ({ commit }) => {
  await apiService.realmSettings.load().then(resposne => {
    commit('globalSettingsMutator', resposne.data)
    return resposne.data.data
  })
}

/**
 * Set Global Setting Action
 * @param commit
 * @param payload
 * @returns {Promise<void>}
 */
export const setGlobalSettingsAction = async ({ commit }, payload) => {
  commit('globalSettingsMutator', payload)
}
