import { format, utcToZonedTime } from 'date-fns-tz'

const timeHelper = {

  convertUnixToGeorgianSeconds (unix) {
    return unix + 62167219200
  },

  convertSecondsToHms (seconds) {
    return new Date(seconds * 1000).toISOString().substr(11, 8)
  },

  /**
   * Date format using timezone from browser
   * @param value
   * @returns {string}
   */
  dateFormatter (value) {
    const zonedDate = utcToZonedTime(value, Intl.DateTimeFormat().resolvedOptions().timeZone)
    return format(zonedDate, 'MM/dd/yyyy h:mma')
  },

  millisToMinutesAndSeconds  (millis) {
    var minutes = Math.floor(millis / 60000)
    var seconds = ((millis % 60000) / 1000).toFixed(0)
    // ES6 interpolated literals/template literals
    // If seconds is less than 10 put a zero in front.
    return `${minutes}:${(seconds < 10 ? '0' : '')}${seconds}`
  }

}

export default timeHelper
