const sipHelper = {

  getTarget (target, domain) {
    if (target.toLowerCase().startsWith('sip:')) {
      return target
    } else {
      return `sip:${target}@${domain}`
    }
  },

  getOptions (extraHeaders = [], eventHandlers = {}) {
    return {
      sessionTimersExpires: 120,
      mediaConstraints: {
        audio: true,
        video: false
      },
      extraHeaders: extraHeaders,
      eventHandlers: eventHandlers
    }
  },

  getRealmFromSip (realm) {
    realm = realm.split('@')

    if (realm.length < 2) {
      return
    }

    return realm[1]
  },

  cleanTarget (target) {
    target = target.toLowerCase()

    if (target.match(/[a-zA-Z]/ig) || ['*', '+'].indexOf(target[0]) !== -1) return target

    return target.replace(/\D/ig, '')
  }
}

export default sipHelper
