<template>
  <div>
    <audio id="ringing-audio" ref="ringing-audio" loop :src="require('@/assets/audio/sounds/ringing.mp3')"></audio>
    <audio id="global-hold-ringing-audio" ref="global-hold-ringing-audio" loop :src="require('@/assets/audio/sounds/beep_sound.mp3')"></audio>
    <audio id="test-ringing-audio" ref="test-ringing-audio" loop :src="require('@/assets/audio/sounds/ringing.mp3')"></audio>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RingingAudioComponent',
  data: () => ({
    ringing: false
  }),
  computed: {
    ...mapGetters('audioVolume', [
      'ringingGetter',
      'testRingingGetter'
    ]),
    ...mapGetters('settings', [
      'audioSettingsGetter'
    ]),
    ...mapGetters('global', [
      'callGetter'
    ])
  },
  watch: {
    ringingGetter (val) {
      if (this.callGetter.isGlobalHoldCall) {
        this.globalHoldRinging(val)
      } else {
        this.callRinging(val)
      }
    },
    testRingingGetter (val) {
      this.$refs['test-ringing-audio'].load()
      this.$refs['test-ringing-audio'].volume = this.audioSettingsGetter.ringingVolume
      this.$refs['test-ringing-audio'].setSinkId(this.audioSettingsGetter.ringingOutputDeviceId)
      val ? this.$refs['test-ringing-audio'].play() : this.$refs['test-ringing-audio'].pause()
    },
    'audioSettingsGetter.ringingOutputDeviceId' (val) {
      this.$refs['ringing-audio'].setSinkId(val)
      this.$refs['test-ringing-audio'].setSinkId(val)
    },
    'audioSettingsGetter.ringingVolume' (val) {
      this.$refs['ringing-audio'].volume = val
      this.$refs['test-ringing-audio'].volume = val
    }
  },
  methods: {
    callRinging (val) {
      this.$refs['ringing-audio'].load()
      this.$refs['ringing-audio'].volume = this.audioSettingsGetter.ringingVolume
      this.$refs['ringing-audio'].setSinkId(this.audioSettingsGetter.ringingOutputDeviceId)
      const unEstablished = this.$sip.session.find(item => !item.isEstablished() && item.status === 4)
      val && unEstablished && !this.callGetter.hasActiveCall ? this.$refs['ringing-audio'].play() : this.$refs['ringing-audio'].pause()
    },
    globalHoldRinging (val) {
      this.$refs['global-hold-ringing-audio'].load()
      this.$refs['global-hold-ringing-audio'].volume = this.audioSettingsGetter.ringingVolume
      this.$refs['global-hold-ringing-audio'].setSinkId(this.audioSettingsGetter.ringingOutputDeviceId)
      const unEstablished = this.$sip.session.find(item => !item.isEstablished() && item.status === 4)
      val && unEstablished && !this.callGetter.hasActiveCall ? this.$refs['global-hold-ringing-audio'].play() : this.$refs['global-hold-ringing-audio'].pause()
    }
  }
}
</script>

<style scoped>

</style>
