<template>
  <div>
    <audio id="new-abandon-call" ref="new-abandon-call"></audio>
  </div>
</template>
<script>
export default {
  name: 'AbandonCallAudioComponent'
}
</script>
